<template>
    <div class="w-full">
		<SiteHeader />
		<section class="w-full py-16 bg-siteRed-100">
			<div class="container mx-auto">
				<h2 class="mb-4 text-6xl leading-10 text-center text-white lg:text-7xl font-cormorant">Benefits</h2>
				<h3 class="text-2xl font-thin text-center text-white font-roboto">Of Our Treatment</h3>
			</div>
			<div class="container pt-8 mx-auto">
				<div class="grid items-start grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-4">
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-02.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Improves</span>Skin Texture & Laxity</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-03.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Improves</span>Collagen Production</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-04.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">Stimulates</span>Hydration Effectively</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-05.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto"><span class="block text-lg font-thin lg:text-xl">More</span>Refreshed Appearance</h2>
						</div>
					</div>
					
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto">
				<div class="w-full mx-auto mb-10 text-center">
					<h2 class="mb-2 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Target Troubled Areas</h2>
					<!-- <span class="text-2xl text-siteRed-100">With Profhilo</span> -->
				</div>
				<div class="grid items-start grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-01.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Forehead<br />& Eye Area</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-02.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Cheeks</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-03.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Neck<br />& Decolletage</h2>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full text-center">
							<img src="/images/vavavoom-img-04.jpg" class="mb-6 rounded-xl" />
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Lower Face<br />& Chin</h2>
						</div>
					</div>
				</div>
				<div class="my-12 border-b border-siteRed-200"></div>
				<div class="flex flex-wrap items-center justify-between">
					<div class="w-full mb-10 lg:w-3/5 lg:mb-0">
						<h3 class="text-2xl font-roboto mb-6">With a keen eye for aesthetics, Dr Vanessa Phua can help you improve the health and appearance of your skin through a wide range of treatments available at the clinic.</h3>
					</div>
					<div class="items-end justify-end w-full lg:w-2/5">
						<div class="items-end float-left lg:float-right"><a href="#enquire" class="flex flex-wrap items-center px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 lg:float-left bg-siteRed-default hover:underline">Enquire Now <img src="/images/arrow-icon.png" class="h-6 ml-4" /></a></div>
					</div>
				</div>
			</div>
		</section>
		<section class="relative flex flex-wrap lg:pb-0 bg-siteRed-100">
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 lg:w-3/5 lg:py-24 lg:pr-8 xl:py-32">
					<div class="mb-10">
						<h2 class="mb-2 text-5xl leading-none text-white lg:text-7xl font-cormorant">Improve Your Skin’s Hydration & Elasticity</h2>
						<span class="text-2xl text-white">With Dr Vanessa Phua</span>
					</div>
					<p class="text-white">When administered by an experienced medical professional, patients are more likely to receive safe and satisfactory outcomes.</p>
					<p class="text-white">Our specialty skin treatment can rejuvenate one’s facial appearance by addressing skin laxity and restoring firmness and moisture to the skin.</p>
					<p class="text-white">Dr Vanessa Phua provides safe and personalised aesthetic treatments at a nett price that includes consultation.</p>
				</div>
				<div class="right-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:absolute lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua.jpg')">
				</div>
			</div>
			<img src="/images/dr-vanessa-phua.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="relative flex flex-wrap w-full pb-12 lg:pb-0">
			<div class="absolute left-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua-photo.jpg')"></div>
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 ml-auto lg:w-3/5 lg:py-24 lg:pl-16 xl:py-32">
					<div class="mb-10">
						<span class="mb-4 text-2xl text-siteRed-100">Meet Our Doctor</span>
						<h2 class="text-5xl leading-none text-siteRed-100 lg:text-7xl font-cormorant">Dr Vanessa Phua</h2>
					</div>
					<ul class="pl-4 mb-6 leading-relaxed list-disc">
						<li class="pb-2 pl-2">BMed Science, BMBS (Nottingham, UK)</li>
						<li class="pb-2 pl-2">MRCS (Edinburgh), MMED (S'pore) Ophthalmology</li>
						<li class="pb-2 pl-2">Dip Practical Dermatology (Cardiff, Wales, UK)</li>
						<li class="pb-2 pl-2">Filler Rejuvenation For Body & Face Contouring Certified</li>
					</ul>
					<p>Dr Vanessa Phua brings with her many years of experience and is a trainer for several forms of facial rejuvenation. Dr Phua sees the pursuit of beauty as a seamless combination of art and science.</p>
					<p>She has a history of training in Ophthalmology and Aesthetic medicine internationally. Educated in the United Kingdom with an Ophthalmology surgical background, she has a keen interest for aesthetic rejuvenation of the individual.</p>
				</div>
			</div>
			<img src="/images/dr-vanessa-phua-photo.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="w-full bg-opacity-0 bg-grad lg:bg-opacity-100">
			<div class="container mx-auto">
				<div class="flex flex-wrap">
					<div class="w-full px-6 pb-16 lg:py-16 lg:w-3/5 bg-siteRed-400 lg:pr-36 lg:pr-0">
						<h2 class="mb-12 text-4xl font-normal leading-none lg:text-4xl 2xl:text-6xl font-cormorant">Our skin treatments are customised to each individual with their <span class="text-siteRed-default">goals, condition, comfort and safety</span> in mind.</h2>
						<a href="#enquire" class="flex flex-wrap items-center float-left px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 bg-siteRed-default hover:underline ">Enquire Now <img src="/images/arrow-icon.png" class="h-6 ml-4" /></a>
					</div>
					<div class="w-full px-6 py-16 lg:w-2/5 bg-siteRed-100 lg:pl-0 lg:pl-20">
						<h3 class="mb-16 text-2xl leading-normal text-white lg:text-4xl">Our specialty treatment for skin hydration and firming has the following features:</h3>
						<div class="flex flex-wrap">
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-06.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-lg font-normal leading-none text-white">Approximately 45-minute procedure</span></div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-07.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-lg font-normal leading-none text-white">Generally well-tolerated during and after</span></div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-09.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-lg font-normal leading-none text-white">Several days for initial effect, several weeks for final result</span></div>
								</div>
							</div>
							<div class="w-full mb-10 md:w-1/2">
								<div class="flex flex-row">
									<div class="w-1/5 mr-4"><img src="/images/icon-11.png" class="w-full"></div>
									<div class="w-4/5"><span class="text-lg font-normal leading-none text-white">Avoid alcohol, smoking and strenuous exercise for 24 hours</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16 bg-siteRed-200">
			<div class="container mx-auto">
				<h2 class="mb-10 text-6xl text-center text-black lg:text-7xl font-cormorant">FAQ</h2>
				<div>
					<div class="mb-2">
            			<div @click="index = 0" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">What is the downtime involved?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 0" class="p-8">
							<p class="mb-4 text-black">You can expect some temporary redness, mild swelling and small bumps immediately following the procedure; which should subside within a few days.</p>
						</div>
						</collapse-transition>
          			</div>
					<div class="mb-2">
            			<div @click="index = 1" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Can different skin treatments be combined?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
            			</div>
						<collapse-transition>
						<div v-show="index === 1" class="p-8">
							<p class="mb-4 text-black">Yes, certain skin treatments can be combined in order to enhance results or address multiple skin issues. Your aesthetic doctor will be able to advise you on suitable treatment combinations based on your individual situation.</p>
						</div>
						</collapse-transition>
          			</div>
        		</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto text-center"><a name="enquire"></a>
				<div class="w-full mx-auto lg:w-3/4">
					<h2 class="mb-10 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Rejuvenate your skin with a more radiant glow</h2>
					<span class="text-2xl text-siteRed-100">Fill up the form to find out more about the treatments we provide.</span>
				</div>
				<div class="w-full mx-auto mt-10 lg:w-3/4">
					<iframe allowTransparency="true" style="min-height:450px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="//feedback.activamedia.com.sg/my-contact-form-5756594.html"></iframe>
				</div>
			</div>
		</section>
		<section class="flex flex-wrap">
			<div class="w-full bg-center bg-no-repeat bg-cover lg:w-1/2" style="background-image: url('/images/clinic-photo.jpg')">
				<img src="/images/clinic-photo.jpg" class="block lg:hidden" />
			</div>
			<div class="flex justify-start w-full lg:w-1/2 bg-siteRed-100">
				<div class="py-12 container-half lg:pl-16 lg:py-24">
					<h2 class="mb-10 text-6xl leading-none text-white lg:text-8xl font-cormorant">Our Clinic</h2>
					<p class="text-white">Established in 1999, The Chelsea Clinic is dedicated to the pursuit of aesthetic excellence and places a strong emphasis on the holistic approach to a patient’s well-being focuses through personalized health care.</p>
					<p class="text-white">Our mission is to provide safe, effective and affordable treatments to our clients without compromising on the quality of our work.</p>
				</div>
			</div>
    	</section>
        <SiteFooter />
    </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		SiteFooter,
		CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
    },
}
</script>
<style>

@screen lg{
	.bg-grad{
		background: rgb(247,247,247);
		background: -moz-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#8a2224",GradientType=1); 
	}
}
</style>